@import "partials/vars";

body {
  padding-top: 51px;
}

.navbar-default {
  background-color: $blue;
  height: 50px;

  .navbar-brand {
    padding-top: 8px;

    svg {
      height: 34px;
      width: auto;
      fill: black;
    }
  }

  ul.navbar-nav {
    li {
      a {
        color: $black;

        &:hover, &:focus {
          color: $black;
        }
      }

      &.dropdown {
        cursor: pointer;

        .caret {
          color: #fff;
          transform: rotate(90deg);
          margin-left: -2px;
        }

        .dropdown-link {
          display: inline-block;
          padding-right: 21px;
        }

        .dropdown-menu {
          background-color: #2281c3;
          
          li {
            a:hover, a:focus {
              background-color: rgba(255, 255, 255, 0.25);
            }
            a {
              line-height: 40px;
              letter-spacing: 1px;
            }
          }

          padding: 0;
        }

        &.open {
          .caret {
            transform: none;
            margin-left: 0;;
          }
        }
      }

      ul.dropdown-menu {
        border-radius: 0;
        margin: 0;
      }
    }
  }

  @media (min-width: $screen-sm-max) {
    .navbar-nav {
      margin: 0 auto;
      display: table;
      table-layout: fixed;
      float: none;
    }
  }

  .nav-pills {
    padding-left: 0;
  }

  @media (max-width: $screen-sm-max) {
    .nav-pills li {
      float: none;
    }
  }
}

/* vim: set expandtab ts=2 sw=2: */
