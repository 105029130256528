$primary-font: sofia-pro, sans-serif;
$font-family-sans-serif: $primary-font; 
$headings-font-family: $primary-font;

$font-size-base: 18px;
$font-size-tiny: 12px;
$font-size-small: 14px;
$font-size-input: 24px;
$font-size-normal: 24px;
$font-size-large: 36px;
$font-size-xl: 42px;
$font-size-giant: 64px;

$font-weight-extra-thin: 400;
$font-weight-thin: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

$font-size-h1: $font-size-giant;
$font-size-h2: $font-size-xl;
$font-size-h3: $font-size-xl;
$font-size-h4: $font-size-large;
$font-size-h5: $font-size-large;
$font-size-h6: $font-size-normal;

$headings-font-weight: $font-weight-bold;
$headings-font-weight-h1: $font-weight-bold;
$headings-font-weight-h2: $font-weight-bold;
$headings-font-weight-h3: $font-weight-normal;
$headings-font-weight-h4: $font-weight-bold;
$headings-font-weight-h5: $font-weight-normal;
$headings-font-weight-h6: $font-weight-normal;

$headings-line-height: $font-size-normal * 1.1;
$headings-line-height-h1: $font-size-h1 * 1.1;
$headings-line-height-h2: $font-size-h2 * 1.1;
$headings-line-height-h3: $font-size-h3 * 1.1;
$headings-line-height-h4: $font-size-h4 * 1.1;
$headings-line-height-h5: $font-size-h5 * 1.1;
$headings-line-height-h6: $font-size-h5 * 1.1;

$jumbotron-heading-font-size: $font-size-h1;


/* vim: set expandtab ts=2 sw=2: */
