// Base Colors
$black:                   #000;
$white:                   #fff;

$red: #f05b40;
$light-red: #f69c8c;
$yellow: #fbae25;
$light-yellow: #fdcf7c;
$green: #01963b;
$light-green: #66c093;
$pine-green: #106a37;
$light-pine-green: #6fa586;
$blue: #1f5fdf;
$light-blue: #799fec;
$navy: #1b1f4a;
$light-navy: #767892;
$teal: #75d0ef;
$light-teal: #ace2f6;

$light-gray: #d7d7d7;
$very-light-gray: #ebebeb;

// Color overrides
$text-color: $navy;
$link-color: $navy;
$dropdown-caret-color: $navy;

$navbar-default-color: $navy;
$navbar-default-bg: $light-teal;
$navbar-default-link-color: $navy;
$navbar-default-link-hover-color: darken($navy, 20%);
$navbar-default-link-disabled-color: lighten($navy, 20%);

$jumbotron-bg: transparent;

$btn-default-color: $navy;
$btn-default-bg: $white;
$btn-default-border: $navy;

$btn-primary-color: $white;
$btn-primary-bg: $blue;
$btn-primary-border: $light-blue;

$btn-success-color: $white;
$btn-success-bg: $green;
$btn-success-border: $light-green;

$btn-info-color: $navy;
$btn-info-bg: $teal;
$btn-info-border: $light-teal;

$btn-warning-color: $white;
$btn-warning-bg: $yellow;
$btn-warning-border: $light-yellow;

$btn-danger-color: $white;
$btn-danger-bg: $red;
$btn-danger-border: $light-red;

$state-success-text: $green;
$state-success-bg: $light-green;
$state-success-border: $green;

$state-info-text: $blue;
$state-info-bg: $light-blue;
$state-info-border: $blue;

$state-warning-text: $yellow;
$state-warning-bg: $light-yellow;
$state-warning-border: $yellow;

$state-danger-text: $red;
$state-danger-bg: $light-red;
$state-danger-border: $red;

$footer-background-color: $navy;
$footer-text-color: $white;
/* vim: set expandtab ts=2 sw=2: */
