@import "vars";

@mixin bg {
    border-radius: $bg-border-radius;
    display: inline-block;
    padding: $bg-padding-vertical $bg-padding-horizontal;
}

@mixin input {
    border-color: $light-gray !important;
    color: $dark-gray !important;
    font-size: $font-size-input !important;
    height: $input-height !important;
    line-height: $font-size-input !important;
    padding-left: $input-horizontal-padding !important;
    padding-right: $input-horizontal-padding !important;

    &:focus {
        border-color: $brand-primary !important;
    }

    &::placeholder {
        color: $gray !important;
    }
}