@import "../../partials/vars-base";

@import "./colors";
@import "./typography";

$mobile-navbar-height: 104px;
$navbar-height: 140px;

$border-radius-base: 10px;

// Import Twitter Bootstrap last.  We have to include it after we redefine variables above.
@import '../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';

/* vim: set expandtab ts=2 sw=2: */
