$primary-font: Lato, sans-serif;
$font-family-sans-serif: Lato, Arial, sans-serif;
$headings-font-family: Lato, Arial, sans-serif;

$font-size-tiny: 10px;
$font-size-small: 12px;
$font-size-input: 18px;
$font-size-normal: 18px;
$font-size-large: 24px;
$font-size-xl: 36px;
$font-size-giant: 48px;

$font-weight-extra-thin: 100;
$font-weight-thin: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$font-size-h1: $font-size-giant;
$font-size-h2: $font-size-xl;
$font-size-h3: $font-size-large;
$font-size-h4: $font-size-normal;
$font-size-h5: $font-size-normal;
$font-size-h6: $font-size-normal;

/****************
 * SIZE CLASSES *
 ****************/
.text-tiny {
    font-size: $font-size-tiny;
}

.text-small {
    font-size: $font-size-small;
}

.text-normal {
    font-size: $font-size-normal;
}

.text-large {
    font-size: $font-size-large;
}

.text-xl {
    font-size: $font-size-xl;
}

.text-giant {
    font-size: $font-size-giant;
}

/******************
 * WEIGHT CLASSES *
 ******************/
.text-thin {
    font-weight: $font-weight-thin;
}

/*****************
 * COLOR CLASSES *
 *****************/
.text-blue {
    color: $blue;
}

.text-dark-gray {
    color: $dark-gray;
}

.text-extra-light {
    color: $very-transparent-gray;
}

.text-gray {
    color: $gray;
}

.text-light {
    color: $transparent-gray;
}

.text-light-gray {
    color: $light-gray;
}

.text-white {
    color: $white;
}

.text-yellow {
    color: $yellow;
}
