// Base colors
$black:                   #000;
$white:                   #fff;

$blue:                    #0070ae;
$light-blue:              #0091e1;
$red:                     #eb002a;
$green:                   #5acd72;

$yellow:                  #ffb600;

$light-gray:              #e1e1e1;
$very-light-gray: #ebebeb;
$gray:                    #a5a5a5;
$dark-gray:               #777676;
$very-dark-gray:          #565656;
$transparent-gray:        rgba(0, 0, 0, 0.5);
$very-transparent-gray:   rgba(0, 0, 0, 0.07);

// Functional colors
$static-page-bg-color:    $light-gray;
$footer-background-color: #ebebeb;
$footer-text-color: $black;

// Funcitonal - Tooltips
$tooltip-bg:              $very-dark-gray;

// Functional - Brand
$brand-primary:           $blue;
$brand-success:           $green;
$brand-info:              $light-blue;
$brand-danger:            $red;

// Functional - Navbar
$navbar-default-link-color: $white;
$navbar-default-bg:         transparent;

// Functional - Navbar toggle
$navbar-default-toggle-hover-bg:     $blue;
$navbar-default-toggle-icon-bar-bg:  $white;
$navbar-default-toggle-border-color: $white;

/* vim: set expandtab ts=2 sw=2: */
