@import "./rechub/vars";
@import "../main";
@import "../neo";
@import "../navbar";

body {
  padding-top: $mobile-navbar-height + 1;

  @media (min-width: $screen-sm-min) {
    padding-top: $navbar-height + 1;
  }
}

.navbar {
  min-height: $mobile-navbar-height;

  @media (min-width: $screen-sm-min) {
    min-height: $navbar-height;
  }
}

.body-wrapper a {
  text-decoration: underline;
}

.btn {
  border-width: 3px;
  border-style: solid;
  font-size: $font-size-input;
  font-weight: $font-weight-bold;
  padding: 25px;

  &.dropdown-toggle {
    border-radius: 0;
  }
}

$space: 0px;
.named-ico {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon-link-caption {
    font-size: 25px;
    white-space: normal;
    margin-top: 8px;
  }
  .ico-wrapper {
    height: 90px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    figure { 
      display: flex;
      z-index: 1;
      box-shadow: none !important;
      svg { height: 100px !important }
    }
    .ico-dot {
      border: 4px solid $red;
      background-color: $light-red;
      position: absolute;
      left: $space;
      right: $space;
      bottom: $space;
      top: $space;
      border-radius: 10000px;
      z-index: 0;
    }
  }
}

@mixin nav-colors {
  // background-color: rgba(255, 255, 255, 0.98);
  &.navbar-red { background-color: lighten($red, 25%); }
  &.navbar-yellow { background-color: lighten($yellow, 25%); }
  &.navbar-light-green { background-color: lighten($light-green, 25%); }
  &.navbar-pine-green { background-color: lighten($pine-green, 25%); }
  &.navbar-blue { background-color: lighten($blue, 25%); }
  &.navbar-teal { background-color: lighten($teal, 25%); }
  
  // Using $navy looks like ass, so just force it to blue.
  &.navbar-navy { background-color: lighten($blue, 25%); }
}

#main-navbar {
  margin-bottom: 0;
  border-bottom: 2px solid white;

  a {
    color: $navy;
  }

  li.active a {
    color: $white;
  }

  @include nav-colors;

  &.navbar-transparent { background-color: rgba(255,255,255,0.6); }

  @media (max-width: $screen-sm-max) {
    .collapsing, .collapse.in {
      padding-left: 0;
      margin-left: -50px;
    }
    .collapsing .navbar-nav,.collapse.in .navbar-nav {
      display: flex;
      margin-top: 1px;
      a {
        padding-bottom: 0;
      }
    }
  }
  .navbar-toggle {
    background-color: $white;
    border-color: $black;
    .icon-bar {
      background-color: $black;
    }

    margin-top: 23px;
    margin-bottom: 23px;
    margin-left: 10px;
    margin-right: 0;
  }
  .branding {
    align-items: flex-start;
    flex-wrap: nowrap;

    margin-top: 16px;

    @media (min-width: $screen-sm-min) {
      flex-wrap: wrap;
      margin-top: 25px;
    }
  }
  .navbar-contents {
    width: 100%;
  }

  .navbar-logo {
    img {
      width: 45px;
      height: 45px;

      @media (min-width: $screen-sm-min) {
        width: 90px;
        height: 90px;
      }
    }
  }

  .word-mark-landing-chooser {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    
    @media (min-width: $screen-sm-min) {
      margin-left: 30px;
    }

    .word-mark {
      margin-right: 12px;
      > svg {
        height: 24px;
        width: auto;
        fill: currentColor;

        @media (min-width: $screen-sm-min) {
          height: 43px;
        }
      }
    }
  }

  #navbar-hamburger.navbar-collapse {
    @media (max-width: $screen-xs-max) {
      margin-left: 0;
      width: 100%;
      position: absolute;
      top: 100px;
      left: 0;

      ul li a {
        font-size: $font-size-large;
        line-height: $font-size-large * 1.1;
      }
      
      @include nav-colors;
    }
   
    @media (min-width: $screen-sm-min) { 
      margin-left: 30px;
      & > ul.nav > li:first-of-type > a {
        padding-left: 0;
      }
    }

    .navbar-right {
      float: none !important;
      margin-right: 0;
      margin-left: 0;
      display: inline-block;
    }

    @media (min-width: $screen-sm-min) {
      &.collapse {
        display: flex !important;
        align-items: center;
      }
    }
  }

  .navbar-nav > li > a {
    padding-top: 0;
    padding-bottom: 0;
    font-size: $font-size-normal; 
    line-height: $font-size-normal * 1.5;
  }

  .mobile-cta {
    font-weight: bold;
  }

  .btn-cta {
    margin-top: 29px;
    margin-right: 0;
    padding: 30px 20px;
    
    font-size: 30px;
    font-weight: $font-weight-bold;
    line-height: 0.64;

    background: transparent;
    border: 5px solid currentColor;
    border-radius: 44.5px;

    // height: $font-size-large * 2;
    align-self: flex-start;
  }

//  $chooserWidth: 275px; // Only needed if rotating...
  #landing-page-chooser-wrapper {
    margin-top: 8px;
    margin-bottom: 8px;
//    width: $chooserWidth;

    @media (min-width: $screen-lg-min) {
      margin-left: 8px;
    }

    ul.dropdown-menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    button#landing-page-chooser.btn,
    button#landing-page-chooser.btn:hover {
      background: transparent;
      text-align: left;

      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0;

      padding-bottom: 8px;
      border-width: 0;
      border-bottom: 3px solid $black;

      font-size: $font-size-base;
      @media (min-width: $screen-lg-min) {
        font-size: $font-size-input;
      }

//      width: $chooserWidth;
      span.placeholder {
        width: 100%;
      }
    }

    span.caret {
      border-left-width: 8px;
      border-right-width: 8px;
      border-top-width: 8px;

      margin-left: 4px;
      @media (min-width: $screen-sm-min) {
        margin-left: 20px;
      }

/*
      &.flash-border {
        border-top-color: $yellow;
        color: $yellow;

        animation-name: flash_border;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
*/
    }

    ul.dropdown-menu {
//      width: $chooserWidth;
    }
  }
}

@keyframes flash_border {
  0% {
    border-top-color: $white;
    color: $white;
  }
  50% {
    border-top-color: $yellow;
    color: $yellow;
  }
  100% {
    border-top-color: $white;
    color: $white;
  }
}

#content {
  padding-bottom: 0;
  max-width: unset;
}

nav.navbar-no-height + #content {
  margin-top: $navbar-height * -1;
}

article > section,
#hero > .hero-contents,
article.landing-page section.draw-with-background > * {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }

  &:before, &:after {
    content: unset;
  }
}

article.landing-page {
  #rundown {
    .contents {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: space-around;

      > .block {
        min-height: unset;
        padding: 10px;
        margin-bottom: 10px;

        @media (min-width: $screen-sm-min) {
          width: 45%;
        }

        .lp-rundown {
          display: flex;
          align-items: center;

          span.icon {
            margin-right: 10px;

            svg {
              fill: currentColor;
              width: 75px;
              height: 75px;

              @media (min-width: $screen-sm-min) {
                width: 100px;
                height: 100px;
              }
            }
          }
        }
      }
    }
  }
}

#hero {
  padding-top: ($navbar-height + 1);

  &.jumbotron {
    min-height: 85vh;
    color: $white;

    h1 {
      font-size: $font-size-h1 * 0.8; line-height: $headings-line-height-h1 * 0.8;
      @media (min-width: $screen-md-min) { font-size: $font-size-h1 * 0.9; line-height: $headings-line-height-h1 * 0.9; }
      @media (min-width: $screen-lg-min) { font-size: $font-size-h1; line-height: $headings-line-height-h1; }
      @media (min-width: 1250px) { font-size: $font-size-h1; }
      
      &.rotating-header {
        font-weight: $font-weight-thin;
        white-space: nowrap;

        span.placeholder {
          font-weight: $font-weight-black;

          // On mobile, move the placeholder to a new line and center it.
/*
          @media (max-width: $screen-xs-max) {
            display: block !important;

            span {
              float: left;
            }

            &:before {
              content: ' ';
              display: block;
            }
          }
*/          
        }
      }
    }
  }
}

.testimonial {
  margin-top: $headings-line-height-h2;

  .client-picture-name {
    align-items: flex-start;
    flex-wrap: nowrap;

    @media (min-width: $screen-sm-min) {
      display: flex;
      flex-direction: row;
    }

    .client-picture {
      text-align: center;

      img {
        max-height: 80px;
      }

      @media (min-width: $screen-sm-min) {
        flex: 2; 
        text-align: center;

        img { max-height: none; }
      }
    }

    .client-name-position {
      flex: 7;

      h2 {
        line-height: $font-size-h2 + 2;
        @media (max-width: $screen-xs-max) {
          line-height: $font-size-h2 * $mobile-scaling-factor + 2;
        }

        &.clientName {
          margin: 0;
        }

        &.clientPosition {
          clear: both;
          margin-top: 0;
          font-weight: $font-weight-normal;
        }
      }
    }
  }
}

section#demo {
  height: auto;
  position: static;

  padding: 50px 38px;

  // Emulate .container
  width: 100vw;
  @media (min-width: $screen-sm-min) {
    padding-left: calc((100vw - #{$container-sm}) / 2);
    padding-right: calc((100vw - #{$container-sm}) / 2);
  }
  @media (min-width: $screen-md-min) {
    padding-left: calc((100vw - #{$container-md}) / 2);
    padding-right: calc((100vw - #{$container-md}) / 2);
  }
  @media (min-width: $screen-lg-min) {
    padding-left: calc((100vw - #{$container-lg}) / 2);
    padding-right: calc((100vw - #{$container-lg}) / 2);
  }



  @media (min-width: $screen-sm-min) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
  }

  #contact-form {
    flex: 1;
    border: 4px solid $light-gray;
    background-color: transparentize($white, 0.5);
    border-radius: 10px;
    padding: 23px;

    @media (min-width: $screen-sm-min) {
      max-width: 440px;
    }

    label {
      font-weight: normal;
    }

    input {
      color: $text-color;
      font-size: $font-size-base !important;
      height: $font-size-base * 3 !important;
    }

    button {
      font-size: $font-size-normal;
      color: $text-color;
      padding: 20px;
      border-radius: 10px;

      box-shadow: 2.1px 2.1px 7px 0 rgba(0, 0, 0, 0.35);
    }

    p.help-block {
      font-size: $font-size-small;
      color: $text-color;
      font-weight: $font-weight-thin;
      padding-left: 2px;
    }

    ul.errors li {
      color: $state-danger-text;
    }
  }

  #footer-contents {
    flex: 2;
    color: $white;

    > h2 {
      margin-top: 0;
    }
  }
}

footer {
}

.team-members {
  &.flex-stacked.horizontal {
    align-items: flex-start;
  }

  text-align: center;

  > figure {
    flex: 1;

    padding: 20px;

    img {
      width: 200px;
      height: 200px;
      border-radius: 100000px;
      margin-bottom: 10px;
    }
  }
}

#why-rechub {
  .block-singleColumnContent {
    margin-bottom: 20px;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 53px;
    }
    
    .header-wrapper > h2 {
      margin-top: 0;
    }

    @media (max-width: $screen-xs-max) {
      img.img-responsive {
        max-width: 60px;
        margin: auto;
      }
    }
  }
}

.block-features-grid {
  margin-bottom: 40px;

  @media (max-width: $screen-xs-max) {
    margin-left: -40px;
    margin-right: -40px;

    .ico-wrapper {
      height: 65px;
      width: 65px;

      figure svg {
        height: 70px !important;
      }
    }

    .named-ico .icon-link-caption {
      font-size: $font-size-small;
    }
  }
}

@media (max-width: $screen-xs-max) {
  #team-pictures-about-rechub {
    display: flex;
    flex-direction: column;

    #team-pictures {
      order: 2;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;

      > div.block {
        flex: 1 1 40%;
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

.pricing-card {
  margin: 15px;

  &.tiny-pricing-card {
    margin: 15px auto;
  }

  width: 100%;
  @media (min-width: $screen-sm-min) {
    width: 50%;
  }
  @media (min-width: $screen-md-min) {
    width: 33%;
  }

  h2 {
    margin-top: 10px;

    hr {
      margin-top: 15px;
      margin-bottom: 15px;
      border-top-color: $text-color;
    }
  }

  ul {
    padding-left: 20px;
  }
}

footer {
  color: $white;
  height: auto;
  min-height: 0;
  position: static;

  a {
    color: $white;
    text-decoration: none;
  }

  .footer-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (min-width: $screen-sm-min) {
      flex-wrap: nowrap;
    }

    // Horizontal line on mobile, vertical otherwise
    .separator {
      margin: 10px 0;
      border-top: 1px solid white;
      width: 100%;

      @media (min-width: $screen-sm-min) {
        border-top: none;
        border-left: 1px solid white;
        margin: 0 10%;
        width: 1px;
      }
    }

    @media (min-width: $screen-sm-min) {
      > * {
        margin-bottom: 50px;
      }
    }

    > nav {
      width: 100%;
      @media (min-width: $screen-sm-min) {
        width: 40%;
      }
      
      ul {
        display: flex;
        flex-wrap: wrap;
        
        li {
          width: 50%;

          a {
            padding-left: 0;
          }
        }
      }
    }
  } // .footer-main

  .footer-bottom 
  {
    margin: 25px 0;

    font-size: $font-size-tiny;
    font-weight: 100;

    nav.navbar {
      min-height: 0;

      ul.navbar-nav {
        margin: 0;
        li {
          a {
            padding: 0 15px;
            float: left;
          }

          &:first-of-type a {
            padding-left: 0;
          }
        }
      }
    }
  } // .footer-bottom
} // .footer

.label-danger { background-color: $light-red; }
.label-info { background-color: $light-teal; }
.label-warning { background-color: $light-yellow; }
.label-success { background-color: $light-green; }
.label-primary { background-color: $light-pine-green; }
.label-default { background-color: $light-navy; }

/* vim: set expandtab ts=2 sw=2: */
