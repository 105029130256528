@import 'colors';
@import 'typography';

// The base size everything will be based on (e.g. margins, div heights, etc)
// This is essentially an alias to $font-size-normal so we can just type $base instead
// Example: Set div height to $base*2 instead of 24px so if we increase the font later everything will change with it
$base: $font-size-normal;

$bg-border-radius:      10px;
$bg-padding-vertical:   14px;
$bg-padding-horizontal: 14px;

$btn-box-shadow: none;

$input-height:             $base * 11/3;
$input-horizontal-padding: 20px;

$navbar-body-padding: 50px;

/*******************************************************************************
 * ALL VARIABLES BELOW THiS POINT ARE OVERRIDES OF TWITTER BOOTSTRAP VARIABLES *
 *******************************************************************************/

// Form states and alerts
$state-info-text:    $brand-primary;
$state-success-text: $brand-success;
$state-danger-text:  $brand-danger;

// Buttons
$btn-default-bg:     black;
$btn-default-color:  $blue;
$btn-default-border: $white;

// Border radius
$border-radius-base:  2px;
$border-radius-large: 2px;
$border-radius-small: 2px;

// Navbar
$navbar-default-border:     none;

// Base font settings
$font-size-base:         $font-size-normal;
$font-family-sans-serif: $primary-font;
$text-color:             $black;
$blockquote-font-size:   $font-size-normal * 1.25;

// Font weights
$headings-font-weight:   $font-weight-normal;
$btn-font-weight:        $font-weight-normal;
$alert-link-font-weight: $font-weight-bold;
$badge-font-weight:      $font-weight-bold;
$close-font-weight:      $font-weight-bold;

$content-width: 1110px;
