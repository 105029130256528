@import 'bootstrap-custom';
@import '../node_modules/font-awesome/scss/font-awesome';

$footer-height: 60px;

svg {
  fill: currentColor;
}

h1 {
  letter-spacing: 0;
}

h2 {
  font-weight: bold;
}

h3, h4, h5, h6 {
  letter-spacing: 1.5px;
}

.home-page-block {
  margin-bottom: 10px !important;

  .homepage-club-name {
    margin: 10px auto;
    font-size: 35px;
    font-weight: normal;
  }
}

.btn {
  font-size: $font-size-xl;
  padding: 0 $padding-base-horizontal;
  box-shadow: $btn-box-shadow;
  white-space: normal;
}

body {
    min-height: 100vh;
    position: relative;
}

#content {
  max-width: 1920px;
  margin: 0 auto;
  padding-bottom: $footer-height + 40;

  .container-fluid {
    >:not(#image-carousel):not(article) {
      margin-top: 30px;
    }

    #image-carousel + * {
      margin-top: 0;
    }
  }
}

footer {
  background-color: $footer-background-color;
  bottom: 0;
  height: $footer-height;
  position: absolute;
  width: 100%;

  a {
    color: #000;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  .navbar {
    align-items: center;
    background-color: $footer-background-color;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    margin: 0;
    width: 100%;

    .footer-menu-items {
      background-color: $footer-background-color;
      line-height: $footer-height;
      position: absolute;
      width: 100%;

      .footer-copyright {
        right: 0;
      }
    }

    .footer-social {
      display: inline-block;
      float: none;
      font-size: 40px;
    }

    ul.navbar-nav {
      text-align: center;
      width: 100%;

      a#copyright {
        text-decoration: none;

        &:hover {
          color: inherit;
        }
      }

      li a {
        line-height: 40px;
        padding: 10px;

        p {
          margin: 0;
        }
      }
    }
  }
}

#navbar {
  background-color: #000;

  @media (min-width: $screen-sm-min) { 
    ul.nav.navbar-nav {
      float: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      text-align: center;
      justify-content: center;

      > li {
        flex: 0 auto;
        float: none;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  li {
    a:hover, a:focus {
      background-color: rgba(255, 255, 255, 0.25);
    }
  }

  li a {
    color: #fff;
    font-size: $font-size-large;
    padding-left: 25px;
    padding-right: 25px;
  }

  li.dropdown {
    cursor: pointer;

    .caret {
      color: #fff;
      transform: rotate(90deg);
      margin-left: -4px;
    }

    .dropdown-link {
      display: inline-block;
      padding-right: 21px;
    }

    .dropdown-menu {
      li {
        a:hover, a:focus {
          background-color: rgba(255, 255, 255, 0.25);
        }
        a {
          line-height: 40px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.25);
          letter-spacing: 1px;
          font-size: 15px;
        }
      }
      
      padding: 0;
    }

    &.open {
      .caret {
        transform: none;
      }
    }
  }

  ul.dropdown-menu {
    border-radius: 0;
    margin: 0;
  }

  @media (min-width: $screen-sm-max) {
    .navbar-nav {
      margin: 0 auto;
      display: table;
      table-layout: fixed;
      float: none;
    }
  }

  .nav-pills {
    padding-left: 0;
  }

  @media (max-width: $screen-sm-max) {
    .nav-pills li {
      float: none;
    }
  }
}

.block-container-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 40px;
  margin-top: 30px;
}

.btn-carousel {
  font-size: $font-size-giant; 
  font-weight: bold;
  line-height: $font-size-giant * 1.5;
  min-width: 200px;
  min-height: 60px;
  box-shadow: 0px 6px 13px 0 rgba(23, 22, 55, 0.65);
  white-space: normal;
  word-wrap: break-word;

  @media (max-width: $screen-sm-max) {
      font-size: $font-size-xl;
      min-height: 50px;
  }

  @media (max-width: 440px) {
    font-size: $font-size-large;
    min-height: 30px;
    padding: 0;
  }
}

.carousel {
  margin-bottom: 30px;
  .carousel-inner {
    .item {
      background-color: #000;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;

      img {
        opacity: 0.5;

        @media (max-width: $screen-sm-min) {
          min-height: 300px;
          object-fit: cover;
        }
      }
    }
  }
}

.carousel-caption {
  bottom: initial;
  top: 50%;
  transform: translateY(-50%);

  h1 {
    font-size: $font-size-h1 * 1.5;
  }

  @media (max-width: $screen-sm-max) {
    h1 {
      font-size: $font-size-h1;
    }
  }
}

.carousel-caption-sub {
  font-size: $font-size-large;

  @media (max-width: $screen-sm-max) {
      font-size: $font-size-base;
  }
}

.b {
  font-weight: bold;
}

.bc {
  font-weight: bold;
  text-align: center;
}

img.img-responsive-centered {
  margin: auto;
  display: block;
  max-width: 100%;
  height: auto;
}

@media (max-width: $screen-xs-max) {
  img.img-responsive-centered {
    width: 100% !important;
  }
}

.icon-overlay {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 51px;
  height: 51px;
  border-radius: 100000px;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.35);
}

.nav-justified > li {
  @media (max-width: $screen-xs-max) {
    float: left;
  }
}

.tc {
  text-align: center;
}

h1.page-heading {
  text-align: center;
}

.row-spacer {
  margin-bottom: 10px;
  margin-top: 10px;

  margin-left: 10%;
  margin-right: 10%;
  border-bottom: 1px solid #88888888;
  border-right: 200px solid transparent;
  border-left: 200px solid transparent;
}

.upcoming-events-block-body {
  max-height: 700px;
  overflow: auto;
  padding-right: 12px;

  .event-entry {
    padding: 8px;

    .date {
      font-size: 26px;
      font-weight: bold;
      line-height: 28px;
    }

    .name-container {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 12px;
      margin-left: 20px;
      margin-right: -20px;

      a {
        margin-bottom: 20px;
        text-align: center;
        cursor: pointer;
      }
    }

    .disc-container { 
      .desc {
        overflow: hidden;
        max-height: 100px;
      }
    } 
  }
}

.modal.upcoming-events-detail {
  .modal-body {
    h1 {
      font-size: $font-size-h1 / 1.5;
    }
    h2 {
      font-size: $font-size-h2 / 1.5;
    }
    h3 {
      font-size: $font-size-h3 / 1.5;
    }
    h4 {
      font-size: $font-size-h4 / 1.5;
    }

    p:empty {
      display: none;
    }
  }
}

.embed-responsive-1by1 {
  padding-bottom: 100%;
}

.embed {
  &.center {
    margin: auto;
  }

  &.right {
    text-align: right; 
  }
}

.embed-width-1by4 {
  width: 25%;
  &.right { margin-left: 75%; }
}

.embed-width-1by3 {
  width: 33.3333%;
  &.right { margin-left: 66.6667%; }
}

.embed-width-1by2 {
  width: 50%;
  &.right { margin-left: 50%; }
}

.embed-width-2by3 {
  width: 66.6667%;
  &.right { margin-left: 33.3333%; }
}

.embed-width-3by4 {
  width: 75%;
  &.right { margin-left: 25%; }
}

.embed-width-full {
  width: 100%;
}

.floating-image-wrapper {
  clear: both;
}

.block.iconLinkGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  > a {
    display: block;
    width: 50%;
    padding: 10px;
    
    @media (min-width: $screen-sm-min) {
      width: 33%;
    }

    figure {
      &:hover {
        box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.28);
      }

      svg {
        height: $font-size-giant;
        color: currentColor;
      }

      figcaption {
        white-space: normal;
      }
    }
  }
}

.flex-stacked {
	display: flex;

	&.vertical {
		flex-direction: column;
    flex-wrap: wrap;
	}

  @media (min-width: $screen-sm-min) {
    &.vertical-sm {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
  @media (min-width: $screen-md-min) {
    &.vertical-md {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
  @media (min-width: $screen-lg-min) {
    &.vertical-lg {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

	&.horizontal {
		flex-direction: row;
		align-items: center;
    flex-wrap: wrap;
	}

	&.justified {
		justify-content: space-between;
	}
}

.imageWithCaption img {
  margin: auto;
}

// Keep bullets aligned with text when centered
center ul {
  margin-left: 0;
  padding-left: 0;

  li {
    list-style-position: inside;
  }
}

// Rotating headers will have spans in inline tags we might need to handle
u * {
  text-decoration: underline;
}

b *, strong * {
  font-weight: bold;
}

i *, em * {
  font-style: italic;
}

// Image with captain block
figure.image-with-captain {
  img {
    max-width: 100%;
    display: block;
  }

  &.img-position-left,
  &.img-position-right {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &.img-position-left, &.img-position-drop-left {
    img {
      padding-right: ($grid-gutter-width / 2);
    }
  }
  &.img-position-right, &.img-position-drop-right {
    img {
      padding-left: ($grid-gutter-width / 2);
    }
  }

  &.img-position-right {
    img {
      order: 1;
    }
  }

  &.img-position-drop-left img {
    float: left;
  }
  &.img-position-drop-right img {
    float: right;
  }

  &.img-position-center img {
    margin: auto;
  }
  &.img-position-full img {
    width: 100% !important;
  }

  &.img-width-1 { img { flex: 1; } figcaption { flex: 11; } }
  &.img-width-2 { img { flex: 2; } figcaption { flex: 10; } }
  &.img-width-3 { img { flex: 3; } figcaption { flex: 9; } }
  &.img-width-4 { img { flex: 4; } figcaption { flex: 8; } }
  &.img-width-5 { img { flex: 5; } figcaption { flex: 7; } }
  &.img-width-6 { img { flex: 6; } figcaption { flex: 6; } }
  &.img-width-7 { img { flex: 7; } figcaption { flex: 5; } }
  &.img-width-8 { img { flex: 8; } figcaption { flex: 4; } }
  &.img-width-9 { img { flex: 9; } figcaption { flex: 3; } }
  &.img-width-10 { img { flex: 10; } figcaption { flex: 2; } }
  &.img-width-11 { img { flex: 11; } figcaption { flex: 1; } }
  &.img-width-12 { display: block; img { width: 100%; }} 
  
  @media (max-width: $screen-sm-min) {
    &.mobile-img-width-1 { img { flex: 1; } figcaption { flex: 11; } }
    &.mobile-img-width-2 { img { flex: 2; } figcaption { flex: 10; } }
    &.mobile-img-width-3 { img { flex: 3; } figcaption { flex: 9; } }
    &.mobile-img-width-4 { img { flex: 4; } figcaption { flex: 8; } }
    &.mobile-img-width-5 { img { flex: 5; } figcaption { flex: 7; } }
    &.mobile-img-width-6 { img { flex: 6; } figcaption { flex: 6; } }
    &.mobile-img-width-7 { img { flex: 7; } figcaption { flex: 5; } }
    &.mobile-img-width-8 { img { flex: 8; } figcaption { flex: 4; } }
    &.mobile-img-width-9 { img { flex: 9; } figcaption { flex: 3; } }
    &.mobile-img-width-10 { img { flex: 10; } figcaption { flex: 2; } }
    &.mobile-img-width-11 { img { flex: 11; } figcaption { flex: 1; } }
    &.mobile-img-width-12 { display: block; img { width: 100%; }} 
  }
  
  &.img-width-1 img { width: (1 * 100% / 12); }
  &.img-width-2 img { width: (2 * 100% / 12); }
  &.img-width-3 img { width: (3 * 100% / 12); }
  &.img-width-4 img { width: (4 * 100% / 12); }
  &.img-width-5 img { width: (5 * 100% / 12); }
  &.img-width-6 img { width: (6 * 100% / 12); }
  &.img-width-7 img { width: (7 * 100% / 12); }
  &.img-width-8 img { width: (8 * 100% / 12); }
  &.img-width-9 img { width: (9 * 100% / 12); }
  &.img-width-10 img { width: (10 * 100% / 12); }
  &.img-width-11 img { width: (11 * 100% / 12); }
  &.img-width-12 img { width: (12 * 100% / 12); }
  
  @media (max-width: $screen-sm-min) {
    &.mobile-img-width-1 img { width: (1 * 100% / 12); }
    &.mobile-img-width-2 img { width: (2 * 100% / 12); }
    &.mobile-img-width-3 img { width: (3 * 100% / 12); }
    &.mobile-img-width-4 img { width: (4 * 100% / 12); }
    &.mobile-img-width-5 img { width: (5 * 100% / 12); }
    &.mobile-img-width-6 img { width: (6 * 100% / 12); }
    &.mobile-img-width-7 img { width: (7 * 100% / 12); }
    &.mobile-img-width-8 img { width: (8 * 100% / 12); }
    &.mobile-img-width-9 img { width: (9 * 100% / 12); }
    &.mobile-img-width-10 img { width: (10 * 100% / 12); }
    &.mobile-img-width-11 img { width: (11 * 100% / 12); }
    &.mobile-img-width-12 img { width: (12 * 100% / 12); }
  }
}

// Blog stuff below
.tags {
  .label {
    margin-bottom: 15px;
    display: inline-block;
    padding-top: .5em;
    padding-bottom: .5em;
  }
}

div.byline {
  margin-bottom: 20px;
  font-size: $font-size-small;
}

h2.page-subtitle {
  text-align: center;
  font-weight: $font-weight-normal;
  font-size: $font-size-normal;
  margin-top: 0 !important;
}

article.post-preview, section.blog-block {
  border: 1px solid  currentColor;
  border-radius: 10px;
  margin-bottom: 30px;
  overflow: hidden;

  > img {
    width: 100%;
  }

  .content {
    margin-top: 15px;
    padding: 0 20px;

    h2.title, h3.section-title {
      margin-top: 0;
    }
  }

  .tags {
    padding: 10px 20px;
  }
}

/* vim: set expandtab ts=2 sw=2: */
