@import "partials/vars";
@import "partials/mixins";
    
body {
    font-weight: $font-weight-normal;
}

hr {
    background-color: $very-transparent-gray;
    color: $very-transparent-gray;
}

input {
    @include input;

    .form-control {
        @extend input;
    }
}   

.align-center {
    align-items: center;
    display: flex;
}

// For some reason Bootstrap Sass doesn't handle these bg classes properly when overriding $brand colors
// We need to manually set the background-color as a result
.bg-info, .bg-primary, .bg-success, .bg-danger {
    @include bg;
}
.bg-info {
    background-color: $brand-info !important;
}
.bg-primary {
    background-color: $brand-primary !important;
}
.bg-success {
    background-color: $brand-success !important;
}
.bg-danger {
    background-color: $brand-danger !important;
}
.btn {
    box-shadow: $btn-box-shadow;

    &.btn-responsive {
        white-space: normal;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.form-control-feedback {
    line-height: $input-height !important;
}

.input-group-btn {
    button {
        box-shadow: none;
        height: $input-height;
    }
}

.no-decoration {
    text-decoration: none;

    &:focus {
        color: inherit !important;
        text-decoration: none;
    }

    &:hover {
        color: inherit !important;
        text-decoration: none;
    }
}

// bootstrap does not appear to pull this class from a variable, so we must manually override it.
.text-danger {
    color: $red !important;
}
embed-responsive.embed-responsive-full-height {
    padding-bottom: 75%;
    padding-bottom: 80vh; 
}

div.checkbox input {
    height: auto !important;
}

// Import Twitter Bootstrap last.  We have to include it after we redifine variables above.
@import '../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

// Include mobile-specific overrides after BS

// Bootstrap doesn't define different vars for each of these, so we need
// to implement the classes / overrides ourselves
h1, .h1 { font-weight: $headings-font-weight-h1; line-height: $headings-line-height-h1; }
h2, .h2 { font-weight: $headings-font-weight-h2; line-height: $headings-line-height-h2; }
h3, .h3 { font-weight: $headings-font-weight-h3; line-height: $headings-line-height-h3; }
h4, .h4 { font-weight: $headings-font-weight-h4; line-height: $headings-line-height-h4; }
h5, .h5 { font-weight: $headings-font-weight-h5; line-height: $headings-line-height-h5; }


// Make headings a bit less obtrusive on mobile. Override the Bootstrap default
// since h2 and h3 have the same size.
$mobile-scaling-factor: 0.8;
@media (max-width: $screen-sm-min) {
  h1, .h1 {
    font-size: $font-size-h1 * $mobile-scaling-factor;
    line-height: $mobile-scaling-factor * $headings-line-height-h1;
  }
  h2, .h2 {
    font-size: $font-size-h2 * $mobile-scaling-factor;
    line-height: $mobile-scaling-factor * $headings-line-height-h2;
  }
  h3, .h3 {
    font-size: $font-size-h3 * $mobile-scaling-factor;
    line-height: $mobile-scaling-factor * $headings-line-height-h3;
  }
  h4, .h4 {
    font-size: $font-size-h4 * $mobile-scaling-factor;
    line-height: $mobile-scaling-factor * $headings-line-height-h4;
  }
  h5, .h5 {
    font-size: $font-size-h5 * $mobile-scaling-factor;
    line-height: $mobile-scaling-factor * $headings-line-height-h5;
  }
  h6, .h6 {
    font-size: $font-size-h6 * $mobile-scaling-factor;
    line-height: $mobile-scaling-factor * $headings-line-height-h6;
  }
}
