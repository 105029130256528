@import "partials/vars";
.block {
  clear: both;
}

.block.block-singleColumnContent {
  padding: 10px;
}

.block-multiColumnContent {
  @media (min-width: $screen-sm-min) { 
    display: flex;

    > * {
      flex: 1;
    }
  }
}

.block-card {
  border: 5px solid currentColor;
  border-radius: 10px;
  padding-top: 10px;
  margin-bottom: 50px;

  > *:not(footer),
  {
    padding: 0 10px;
  }

  header,
  > .block-singleColumnContent {
    padding: 0 20px;
  }

  header {
    @media (min-width: $screen-sm-min) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    span.main-card-icon {
      float: right;
      @media (min-width: $screen-sm-min) {
        float: none;
      }

      margin-right: 10px;
      svg {
        height: $font-size-h2;
      }
    }

    h2 {
      margin-top: 0;
    }
  }

  footer {
    background-color: $light-gray;

    // Override default styles for footer
    position: static;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: calc(100% - 2px);
    margin: 0 1px;

    display: flex;
    flex-direction: row;
    align-items: center;

    .legend-icon-wrapper {
      padding: 5px;

      display: flex;
      flex-direction: row;
      align-items: center;

      .legend-icon {
        margin-right: 5px;

        & > svg {
          height: 30px;
        }
      }
    }
  }
}

.draw-with-border {
  border: 5px solid currentColor;
  border-radius: 10px;
}

.drop-shadow {
  box-shadow: 3px;
}

$ctaHeight: 90px;

.block-ctaButton {
  display: block;
  width: 100%;
  position: relative;
  text-align: center;
  height: $ctaHeight;
}

.cta-next {
  margin-bottom: $ctaHeight * -1 !important;
  padding-bottom: $ctaHeight / 2 !important;
}

.cta-next + .block-ctaButton {
  top: $ctaHeight / 2;
}

.has-cta + .block {
  padding-top: $ctaHeight / 2;
}

.block-hero {
  h2 p {
    font-size: $font-size-h2;
    font-weight: $headings-font-weight-h2;
  }
}

.draw-with-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 300px;
  width: 100%;
}

article {
  & > .block {
    padding-left: 15px;
    padding-right: 15px;
  }
}

// Extends the background outside the container, while adding padding so content doesn't move.
#content { 
  overflow: hidden;

  & > .container-fluid {
    & > article {
      & > .block-hero,
      & > .block.draw-with-background {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        max-width: 1000%;
        width: auto;
      }
    }
  }
}

.block-cardsWithToc {
  aside {
    display: none;
//    @media (min-width: $screen-sm-min) {
//      display: block;
//    }

    margin-left: -20px;
    line-height: $font-size-normal;

    > nav#cards-toc {
      box-shadow: 2.8px 2.8px 7px 0 rgba(0, 0, 0, 0.35);
      border: solid 2px currentColor;
      border-radius: 0 10px 10px 0;

			&.affix {
  			top: 152px;
        width: calc(16.66667% - 30px);
      }
    }

    ul:first-of-type {
      padding-left: 0;
    }

    li {
      list-style: none;

      a {
        font-size: $font-size-small;
        line-height: $font-size-small;
      }

      &.active > a,
      a:hover,
      a:focus {
        background-color: $very-light-gray;
        font-weight: bold;
        border-top-left-radius: 10px;
        border-right: 1px solid currentColor;
      }

      &.active, &:hover, &:focus {
        > ul.nav {
          display: block;
        }
      }
    }

    li > ul.nav {
      display: none;
    
      & > li > a {
        padding: 5px 20px;
      }
    }
  }

  main {
    width: 100% !important;
  }
  @media (min-width: $screen-sm-min) {
    main {
//      width: calc(83.33333% + 20px);
    }
  }
}


/* vim: set expandtab ts=2 sw=2: */
